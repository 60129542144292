<template>
  <el-dialog :title="titleText" :visible.sync="requestDialog" width="980px" :modal="false" :close-on-click-modal="false"
    @close="closeDialog" custom-class="project_dialog">
    <section class="propose_project">
      <div class="px-3 course_box">
        <el-form label-position="top" label-width="80px" :model="formLabelAlign">
          <el-form-item class="star" label="Project name">
            <el-input v-model="formLabelAlign.project_name"></el-input>
          </el-form-item>
          <el-form-item class="star" label="Organization">
            <el-row :gutter="5">
              <OrganizationApply @getOrganizationParams="getOrganizationApply" :spanValue='6' />
            </el-row>
          </el-form-item>
          <el-form-item label="Contact person for the project">
            <el-input v-model="formLabelAlign.contact"></el-input>
          </el-form-item>
          <el-form-item label="Duration of the project">
            <el-input v-model="formLabelAlign.duration"></el-input>
          </el-form-item>
          <el-form-item class="star" label="RISE Pillars category">
            <el-select placeholder="Please select" v-model="formLabelAlign.project_category" clearable
              style="width: 100%;" multiple>
              <el-option v-for="(v,i) in categoryList" :value="v.value" :key="v.value" :label="v.label">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="star" label="Areas of Opportunity category">
            <el-select placeholder="Please select" v-model="formLabelAlign.area_category" clearable style="width: 100%;"
              multiple>
              <el-option v-for="(v,i) in opportunityList" :value="v.value" :key="v.value" :label="v.label">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="star" label="Year of the project">
            <el-date-picker style="width: 100%" clearable v-model="formLabelAlign.year" type="year" value-format="yyyy">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="Project Manager name">
            <el-input v-model="formLabelAlign.project_manager_name"></el-input>
          </el-form-item>
          <el-form-item label="Contact email or phone number">
            <el-input v-model="formLabelAlign.whatsApp_number"></el-input>
          </el-form-item>
          <el-form-item label="Picture of the Project Manager">
            <div class="d-flex align-items-end">
              <el-upload class="avatar-uploader mr-3" action="no" :show-file-list="false"
                :http-request="uploadIMGManage" v-loading="loadingManage">
                <img v-if="formLabelAlign.project_manager_photo" :src="formLabelAlign.project_manager_photo" alt="" />
                <img v-else src="@/assets/images/settings/camera.png" alt="" />
              </el-upload>
              <span class="text12">(Aspect ratio: 1 to 1)</span>
            </div>
          </el-form-item>
          <el-form-item class="star" :label="summary_label">
            <section class="position-relative">
              <el-input type="textarea" :rows="3" resize="none" v-model="formLabelAlign.summary"
                @blur="val=>limitInput(val,summary_config,formLabelAlign.summary)"
                @keyup.32.native="e=>limitInput(e,summary_config,formLabelAlign.summary)"></el-input>
              <div class="position-absolute" style="line-height: 1.5;">
                <span v-show="summary_config.warning" style="color:#F56C6C;">Cannot exceed {{summary_config.limit}}
                  words</span>
              </div>
              <div class="position-absolute" style="right: 0;line-height: 1.5;" v-if="summary_config.limit">
                <span class="text12">
                  <span :style="{'color':summary_config.textNum>summary_config.limit?'#F56C6C':''}">
                    {{summary_config.textNum }}</span> / {{summary_config.limit}}
                </span>
              </div>
            </section>
          </el-form-item>
          <!-- <el-form-item :label="objective_label">
            <el-input type="textarea" :rows="3" v-model="formLabelAlign.objective"
              @blur="val=>limitInput(val,objective_config,formLabelAlign.objective)"></el-input>
            <div class="position-absolute" style="line-height: 1.5;">
              <span v-show="objective_config.warning" style="color:#F56C6C;">Cannot exceed {{objective_config.limit}}
                words</span>
            </div>
          </el-form-item>
          <el-form-item :label="achievement_label">
            <el-input type="textarea" :rows="3" v-model="formLabelAlign.achievement"
              @blur="val=>limitInput(val,achievement_config,formLabelAlign.achievement)"></el-input>
            <div class="position-absolute" style="line-height: 1.5;">
              <span v-show="achievement_config.warning" style="color:#F56C6C;">Cannot exceed
                {{achievement_config.limit}}
                words</span>
            </div>
          </el-form-item>
          <el-form-item :label="positive_impact_label">
            <el-input type="textarea" :rows="3" v-model="formLabelAlign.positive_impact"
              @blur="val=>limitInput(val,positive_impact_config,formLabelAlign.positive_impact)"></el-input>
            <div class="position-absolute" style="line-height: 1.5;">
              <span v-show="positive_impact_config.warning" style="color:#F56C6C;">Cannot exceed
                {{positive_impact_config.limit}} words</span>
            </div>
          </el-form-item> -->
          <el-form-item class="star"
            label="Upload two pictures. The main picture will be the cover photo of the project and the other will be seen inside of the project details."
            style="margin-top: 30px;">
            <p class="text12" style="margin:-16px 0 0 8px;color:#999">Maximum upload file size: 5MB</p>
            <section class="d-flex picture_flex" style="margin:-12px 0 0 8px">
              <div>
                <span class="text12">Main Picture to feature on the Project Gallery:</span>
                <section class="d-flex text_flex">
                  <el-upload class="avatar-uploader mr-3" action="no" :show-file-list="false"
                    :http-request="uploadIMGMain" v-loading="loadingMain">
                    <img v-if="formLabelAlign.main_picture" :src="formLabelAlign.main_picture" alt="" />
                    <img v-else src="@/assets/images/settings/camera.png" alt="" />
                  </el-upload>
                  <span class="text12">Aspect ratio: 2 to 1 (width to height)</span>
                </section>
              </div>
              <div style="margin-left: 100px;" class="picture_left">
                <span class="text12">Seen inside of the project details page:</span>
                <section class="d-flex text_flex">
                  <el-upload class="avatar-uploader mr-3" action="no" :show-file-list="false" :http-request="uploadIMG"
                    v-loading="loadingApply">
                    <img v-if="formLabelAlign.detail_picture" :src="formLabelAlign.detail_picture" alt="" />
                    <img v-else src="@/assets/images/settings/camera.png" alt="" />
                  </el-upload>
                  <span class="text12">Aspect ratio: 2 to 1 (width to height)</span>
                </section>
              </div>
            </section>
          </el-form-item>
        </el-form>
        <el-checkbox v-model="checked">I acknowledge that I own the picture being submitted.</el-checkbox>
      </div>

      <div class="p-3 d-flex justify-content-between next_apply_btn">
        <el-button type="warning" plain
          :disabled="!checked||summary_config.warning||objective_config.warning||achievement_config.warning||positive_impact_config.warning"
          @click="saveDraft">Save draft</el-button>
        <el-button type="primary"
          :disabled="!checked||summary_config.warning||objective_config.warning||achievement_config.warning||positive_impact_config.warning"
          @click="funSubmit">Submit</el-button>
      </div>
    </section>
  </el-dialog>
</template>
<script>
  import OrganizationApply from '@/components/ComComponents/OrganizationApply'
  export default {
    components: {
      OrganizationApply
    },

    props: {
      titleText: {
        type: String,
        default: 'Create a Project'
      }
    },

    data() {
      return {
        opportunityList: [
          { value: '1', label: 'Business and Entrepreneurship' },
          { value: '2', label: 'International Cooperation' },
          { value: '3', label: 'Community Impact' },
          { value: '4', label: 'Individual Development' }
        ],
        categoryList: [
          { value: '1', label: 'Preserving Mental Health' },
          { value: '2', label: 'Sustaining and Rebuilding Economies' },
          { value: '3', label: 'Workforce Motivation' }
        ],
        requestDialog: false,
        formLabelAlign: {
          project_name: '',
          area_id: '',
          area: '',
          national_id: '',
          national: '',
          local_id: '',
          local: '',
          organization_param: '',
          contact: '',
          duration: '',
          project_category: [],
          area_category: [],
          year: '',
          summary: '',
          objective: '',
          achievement: '',
          positive_impact: '',
          main_picture: '',
          detail_picture: '',
          project_manager_photo: '',
          whatsApp_number: '',
          project_manager_name: '',
        },
        loadingApply: false,
        loadingMain: false,
        loadingManage: false,
        checked: false,
        warning: false,
        summary_config: {
          limit: 0,
          warning: false,
          textNum: 0
        },
        objective_config: {
          limit: 0,
          warning: false
        },
        achievement_config: {
          limit: 0,
          warning: false
        },
        positive_impact_config: {
          limit: 0,
          warning: false
        },
        summary_label: "Summary of the project",
        // summary_label: "1、Summary of The Project",
        objective_label: "What were the primary objectives of the project?",
        achievement_label: "Please list the key milestones or achievements accomplished during the project.",
        positive_impact_label: "Describe the positive impact the project had on the organization, its stakeholders, or the community.",
      }
    },

    methods: {
      getOrganizationApply(data) {
        this.formLabelAlign.organization_param = JSON.stringify(data)
        let OrganData = JSON.parse(JSON.stringify(data))
        let { local, regional, national, area } = OrganData
        this.formLabelAlign.area_id = area.id || 0
        this.formLabelAlign.area = area.name || ''
        this.formLabelAlign.national_id = national.id || 0
        this.formLabelAlign.national = national.name || ''
        this.formLabelAlign.local_id = local.id || ''
        this.formLabelAlign.local = local.name || ''
      },

      async openDialog(id) {
        this.clearFormLabelAlign()
        this.getProjectConfig()
        this.checked = true
        this.id = id
        await this.$http.getProjectDetail({ id }).then((res) => {
          if (res.status == 200) {
            Object.keys(this.formLabelAlign).forEach(k => {
              if (res.data[k] && k != 'language') this.formLabelAlign[k] = res.data[k]
            })
            if (res.data.project_category) {
              this.formLabelAlign.project_category = res.data.project_category.split(',')
            } else {
              this.formLabelAlign.project_category = []
            }
            if (res.data.area_category) {
              this.formLabelAlign.area_category = res.data.area_category.split(',')
            } else {
              this.formLabelAlign.area_category = []
            }

            if (res.data.organization_param) {
              this.$bus.$emit('getBusParentDataApply', JSON.parse(res.data.organization_param))
            }
          }
        })
        if (this.formLabelAlign.summary) this.limitInput('', this.summary_config, this.formLabelAlign.summary)
        this.requestDialog = true
      },

      closeDialog() {
        if (this.isDialogClose) return false
        this.$confirm(this.$t('Posts.488@close_the_pop_up_box'), 'Tips', {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.clearFormLabelAlign()
        }).catch(() => {
          this.requestDialog = true
        })
      },

      // 新增项目---存为草稿
      saveDraft() {
        this.addOrEditProject(0)
      },

      // 新增项目---发布
      funSubmit() {
        this.addOrEditProject(1)
      },

      // 项目配置
      async getProjectConfig(status) {
        let res = await this.$http.getProjectConfig({})
        if (res.status == 200) {
          this.summary_config.limit = res.data.summary_count
          this.objective_config.limit = res.data.objective_count
          this.achievement_config.limit = res.data.achievement_count
          this.positive_impact_config.limit = res.data.positive_impact_count
          if (res.data.summary_count) this.summary_label = `Summary of the project(Maximum of ${res.data.summary_count} words)`
          if (res.data.objective_count) this.objective_label = `What were the primary objectives of the project?(No more than ${res.data.objective_count} words)`
          if (res.data.achievement_count) this.achievement_label = `Please list the key milestones or achievements accomplished during the project.(No more than ${res.data.achievement_count} words)`
          if (res.data.positive_impact_count) this.positive_impact_label = `Describe the positive impact the project had on the organization, its stakeholders, or the community.(No more than ${res.data.positive_impact_count} words)`
        }
      },

      // 新增/编辑项目
      async addOrEditProject(status) {
        let res = await this.$http.addOrEditProject({
          id: this.id,
          status,
          ...this.formLabelAlign
        })
        if (res.status == 200) {
          this.isDialogClose = true
          this.requestDialog = false
          this.$parent.getProjectList()
          this.$message.success(res.message)
        }
      },

      async limitInput(val, item, keyword) {
        if (!item.limit) return
        let res = await this.$http.getContentNumber({ keyword })
        if (res.status == 200) {
          item.textNum = res.data
          if (res.data > item.limit) {
            item.warning = true
          } else {
            item.warning = false
          }
        }
      },

      // 上传图片
      uploadIMGMain(params) {
        var formData = new FormData()
        formData.append('file', params.file)
        this.loadingMain = true
        this.$http.uploadImage(formData).then((res) => {
          if (res.status == 200) {
            this.formLabelAlign.main_picture = res.data[0]
          }
          setTimeout(() => { this.loadingMain = false }, 1000)
        })
      },

      // 上传图片
      uploadIMGManage(params) {
        var formData = new FormData()
        formData.append('file', params.file)
        this.loadingManage = true
        this.$http.uploadImage(formData).then((res) => {
          if (res.status == 200) {
            this.formLabelAlign.project_manager_photo = res.data[0]
          }
          setTimeout(() => { this.loadingManage = false }, 1000)
        })
      },

      // 上传图片
      uploadIMG(params) {
        var formData = new FormData()
        formData.append('file', params.file)
        this.loadingApply = true
        this.$http.uploadImage(formData).then((res) => {
          if (res.status == 200) {
            this.formLabelAlign.detail_picture = res.data[0]
          }
          setTimeout(() => { this.loadingApply = false }, 1000)
        })
      },

      closeDialog() {
        if (this.isDialogClose) return false
        this.$confirm(this.$t('Posts.488@close_the_pop_up_box'), 'Tips', {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.clearFormLabelAlign()
        }).catch(() => {
          this.requestDialog = true
        })
      },

      clearFormLabelAlign() {
        Object.keys(this.formLabelAlign).forEach((key) => {
          this.formLabelAlign[key] = ''
        })
        this.summary_config.warning = false
        this.summary_config.textNum = 0
        this.objective_config.warning = false
        this.achievement_config.warning = false
        this.positive_impact_config.warning = false
        this.checked = false
      }

    }
  }
</script>
<style lang="scss" scoped>
  .propose_project {
    position: relative;

    .course_box {
      padding: 28px 0 18px;
      border-top: 1px solid #f1f1f1;
      border-bottom: 1px solid #f1f1f1;
    }

    /deep/ .el-form-item__label {
      line-height: 20px;
      word-break: normal;
    }

    /deep/ .el-form--label-top .el-form-item__label {
      padding: 0
    }

    /* /deep/ .el-form-item__label::before, */
    .star {
      position: relative;
    }

    .star::before {
      display: inline-block;
      position: absolute;
      content: '*';
      color: red;
      top: -2px;
      left: -8px;
    }

    .avatar-uploader {
      width: 90px;
      height: 90px;
      overflow: hidden;

      img {
        width: 90px;
        height: 90px;
      }
    }
  }

  @media (max-width: 600px) {
    ::v-deep .project_dialog {
      width: 100% !important;
      height: 100vh;
      margin-top: 0 !important;
      margin: 0 auto;
      overflow-y: auto;
    }

    .picture_flex {
      flex-direction: column;
    }

    .text_flex {
      flex-direction: column;
    }

    .picture_left {
      margin-left: 0 !important;
    }

    .el-message-box {
      width: 100px !important;
    }
    ::v-deep .el-form-item__label{
      font-size: 12px;
    }
    .el-form-item{
      margin-bottom: 10px;
    }
    .text12{
      font-size: 12px;
    }
    ::v-deep .el-checkbox__label{
      font-size: 12px;
    }
  }
</style>

<style lang="scss">
  @media (max-width: 600px) {
    .el-message-box {
      width: 100% !important;
    }
  }
</style>